/*
 * Import reset and base styles
 */
import '../libs/styles/reset.scss';
import '../libs/styles/responsive.scss';

import './styles/custom-properties.scss';
import './styles/common.scss';
import './styles/fonts.scss';

import '../libs/reactive-forms/control-link';

/*
 * Import your components here
 */
import './components';

import { ComponentRegistry } from 'libs/components';
import { Provider } from 'libs/provider';
import { ResponsiveTarget } from './responsive-target';
import { NavigationService } from './services/navigation.service';
import { ResizeService } from './services/resize.service';
import { ResponsiveService } from './services/responsive.service';
import { ScrollingService } from './services/scrolling.service';
import { YoutubeService } from './services/youtube.service';
import { ContactFormStorage } from './components';

function main() {
  setupResponsiveService();

  Provider.provide(ContactFormStorage, new ContactFormStorage());

  Provider.provide(ScrollingService, new ScrollingService());
  Provider.provide(NavigationService, new NavigationService());

  const youtubeService = new YoutubeService();
  Provider.provide(YoutubeService, youtubeService);

  // Load after the rest is initialize to prevent blocking
  youtubeService.loadAPI();

  ComponentRegistry.start();
}

window.addEventListener(
  'DOMContentLoaded',
  () => {
    main();
  },
  { once: true }
);

function setupResponsiveService() {
  const resizeService = new ResizeService();
  Provider.provide(ResizeService, resizeService);

  const responsiveService = new ResponsiveService<ResponsiveTarget>(
    resizeService
  );
  responsiveService.addTarget({
    key: ResponsiveTarget.ExtraSmall,
    minWidth: 0,
    maxWidth: 320,
  });
  responsiveService.addTarget({
    key: ResponsiveTarget.Small,
    minWidth: 321,
  });
  responsiveService.addTarget({
    key: ResponsiveTarget.Medium,
    minWidth: 768,
  });
  responsiveService.addTarget({
    key: ResponsiveTarget.Large,
    minWidth: 1192,
  });
  responsiveService.addTarget({
    key: ResponsiveTarget.ExtraLarge,
    minWidth: 1680,
  });
  responsiveService.setup();

  Provider.provide(ResponsiveService, responsiveService);
}
