import { Component, ComponentRegistry } from 'libs/components';
import { clamp } from 'libs/math';
import { GestureHandler } from './gesture-handler';
import { Slide } from './slide';

export class Slider extends Component {
  private activeIndex = 0;
  private activeSlide: Slide | null = null;
  private slides: Slide[] = [];

  private indicatorsContainer: HTMLElement;
  private indicators: HTMLElement[] = [];
  private activeIndicator: HTMLElement | null = null;

  private background: HTMLElement;
  private previousButton: HTMLButtonElement;
  private nextButton: HTMLButtonElement;

  private gestureHandler: GestureHandler;

  override onInit(): void {
    this.getElements();
    this.createSlides();
    this.createIndicators();
    this.addButtonListeners();
    this.addGestureHandler();
    this.goToSlide(this.activeIndex);
  }

  private getElements() {
    this.background = this.host.querySelector('.slider__background-shape');
    this.previousButton = this.host.querySelector('.slider-button--previous');
    this.nextButton = this.host.querySelector('.slider-button--next');
  }

  private createSlides() {
    const hosts = Array.from(this.host.querySelectorAll<HTMLElement>('.slide'));

    for (const host of hosts) {
      this.slides.push(new Slide(host));
    }
  }

  private createIndicators() {
    this.indicatorsContainer = this.host.querySelector('.slider__indicators');

    for (let i = 0; i < this.slides.length; i++) {
      const element = document.createElement('div');
      element.className = 'slide-indicator';

      element.addEventListener('click', async () => {
        await this.goToSlide(i);
      });

      this.indicatorsContainer.appendChild(element);
      this.indicators.push(element);
    }
  }

  private addButtonListeners() {
    this.previousButton.addEventListener('click', () => {
      this.previous();
    });

    this.nextButton.addEventListener('click', () => {
      this.next();
    });
  }

  private addGestureHandler() {
    this.gestureHandler = new GestureHandler(this.host);

    this.gestureHandler.swipeLeft.subscribe(() => this.next());
    this.gestureHandler.swipeRight.subscribe(() => this.previous());
  }

  private async next() {
    await this.goToSlide(this.activeIndex + 1);
  }

  private async previous() {
    await this.goToSlide(this.activeIndex - 1);
  }

  private async goToSlide(index: number) {
    this.activeIndex = clamp(index, 0, this.slides.length - 1);

    await this.activeSlide?.deactivate();
    this.activeSlide = this.slides[this.activeIndex];
    this.background.style.backgroundColor = `${this.activeSlide.backgroundColor}`;
    await this.activeSlide.activate();

    this.activeIndicator?.classList.remove('slide-indicator--active');
    this.activeIndicator = this.indicators[this.activeIndex];
    this.activeIndicator.classList.add('slide-indicator--active');

    this.updateButtonVisibility();
  }

  private updateButtonVisibility() {
    this.previousButton.disabled = this.activeIndex === 0;
    this.nextButton.disabled = this.activeIndex === this.slides.length - 1;
  }
}

ComponentRegistry.declare('.slider', Slider);
