import { AbstractControl } from './abstract-control';

interface ControlDict<T> {
  [key: string]: AbstractControl<T[keyof T]>;
}

export class FormGroup<T> extends AbstractControl<T> {
  private controls = new Map<string, AbstractControl<T[keyof T]>>();

  constructor(controls: ControlDict<T>) {
    super();

    const value = {};

    for (const [key, control] of Object.entries(controls)) {
      this.controls.set(key, control);

      value[key] = control.value;

      control.valueChanges.subscribe((value) => {
        this.setValue({
          ...this.value,
          ...{ [key]: value },
        });
      });
    }

    this._initialValue = value as T;
    this.setValue(this._initialValue);
  }

  protected override updateValidity(): void {
    let isValid = true;

    for (const [, control] of this.controls) {
      if (!control.isValid) {
        isValid = false;
      }
    }

    this._isValid = isValid;
    this._isValid$.next(this._isValid);
  }

  protected override updateIsDirty(): void {
    if (this._isDirty) {
      return;
    }

    let isDirty = false;

    for (const [, control] of this.controls) {
      if (control.value !== control.initialValue) {
        isDirty = true;
      }
    }

    this._isDirty = isDirty;
  }

  get(name: keyof T): AbstractControl<T[keyof T]> {
    return this.controls.get(name.toString());
  }
}
