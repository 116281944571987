import { ValidatorFunction } from './validators';
import { AbstractControl } from './abstract-control';
import { TransformerFunction } from './transformers';

export class FormControl<T> extends AbstractControl<T> {
  constructor(
    initialValue: T,
    validators: ValidatorFunction<T>[],
    transformers: TransformerFunction<T>[] = []
  ) {
    super();

    this._initialValue = initialValue;
    this.validators = validators;
    this.transformers = transformers;
    this.setValue(this._initialValue);
  }

  protected override updateValidity() {
    let isInvalid = false;

    for (const validator of this.validators) {
      const response = validator(this.value);

      if (!response.isValid) {
        isInvalid = true;
      }
    }

    this._isValid = !isInvalid;
    this._isValid$.next(this._isValid);
  }

  protected override updateIsDirty(): void {
    this._isDirty = this.value !== this.initialValue;
  }
}
