import { Observable, Subject } from 'rxjs';

export type GestureEventType = 'swipeleft' | 'swiperight';

export class GestureHandler {
  private readonly MAX_GESTURE_TIME = 400;
  private readonly MIN_X_DELTA = 15;
  private readonly MAX_Y_DELTA = 15;

  private _swipeLeft$ = new Subject<void>();
  private _swipeRight$ = new Subject<void>();

  private isSwiping = false;
  private startTime = 0;

  private startY = 0;
  private startX = 0;

  private lastY = 0;
  private lastX = 0;

  private xDelta = 0;
  private yDelta = 0;

  constructor(private host: HTMLElement) {
    this.addEventListeners();
  }

  private addEventListeners() {
    this.host.addEventListener('touchstart', (event: TouchEvent) => {
      const touch = event.touches[0];
      this.onStart(touch.clientX, touch.clientY);
    });
    this.host.addEventListener('mousedown', (event: MouseEvent) => {
      this.onStart(event.clientX, event.clientY);
    });

    this.host.addEventListener('touchmove', (event: TouchEvent) => {
      const touch = event.touches[0];
      this.onMove(touch.clientX, touch.clientY);
    });
    this.host.addEventListener('mousemove', (event: MouseEvent) => {
      if (this.isSwiping) {
        this.onMove(event.clientX, event.clientY);
      }
    });

    this.host.addEventListener('touchend', () => {
      this.onEnd();
    });
    this.host.addEventListener('mouseup', () => {
      this.onEnd();
    });
  }

  private onStart = (x: number, y: number) => {
    this.isSwiping = true;
    this.startTime = Date.now();
    this.startX = x;
    this.startY = y;
  };

  private onMove = (x: number, y: number) => {
    this.lastX = x;
    this.lastY = y;

    this.xDelta = Math.abs(this.lastX - this.startX);
    this.yDelta = Math.abs(this.lastY - this.startY);
  };

  private onEnd = () => {
    const duration = Date.now() - this.startTime;

    const isDurationOk = duration < this.MAX_GESTURE_TIME;
    const isYOk = this.yDelta <= this.MAX_Y_DELTA;
    const isXOk = this.xDelta >= this.MIN_X_DELTA;

    if (isDurationOk && isYOk && isXOk) {
      this.emitSwipeEvent();
    }

    this.isSwiping = false;
  };

  private emitSwipeEvent() {
    const direction = this.lastX - this.startX;

    if (direction > 0) {
      this._swipeRight$.next();
    } else if (direction < 0) {
      this._swipeLeft$.next();
    }
  }

  get swipeLeft(): Observable<void> {
    return this._swipeLeft$.asObservable();
  }

  get swipeRight(): Observable<void> {
    return this._swipeRight$.asObservable();
  }
}
