import { ValidatorFunction } from './validator-function';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const requiredValidator: ValidatorFunction<any> = (value: string) => {
  const isValid = !!value;

  return {
    value,
    isValid,
  };
};
