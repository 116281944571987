import { BehaviorSubject, Observable } from 'rxjs';
import { remap } from 'libs/math';
import { transition } from 'libs/animation/transition';
import { easeOutQuint } from 'libs/animation/easing';

export class ScrollingService {
  private body: HTMLElement;

  private scrollY$ = new BehaviorSubject<number>(0);
  get scrollY(): Observable<number> {
    return this.scrollY$.asObservable();
  }

  private frozenY: number;
  private preventManualScrolling = false;

  constructor() {
    this.body = document.body;

    window.addEventListener(
      'load',
      () => {
        window.addEventListener(
          'scroll',
          (event) => {
            if (this.preventManualScrolling) {
              event.preventDefault();
            }

            this.scrollY$.next(window.scrollY);
          },
          { passive: false }
        );
      },
      { once: true }
    );
  }

  scrollToId(id: string) {
    let top = 0;

    if (id.length > 1) {
      const target = document.querySelector(id) as HTMLElement;
      top = target.getBoundingClientRect().top + this.scrollY$.value;
    }

    return new Promise<void>((resolve) => {
      this.preventManualScrolling = true;

      const currentY = this.scrollY$.value;
      const distance = Math.abs(currentY - top);
      const maxScrollDistance = document.body.scrollHeight;
      // tslint:disable-next-line:no-bitwise
      const duration = remap(distance, 0, maxScrollDistance, 500, 2000) | 0;

      transition({
        from: currentY,
        to: top,
        duration: duration,
        ease: easeOutQuint,
        onUpdate: (scrollY: number) => {
          window.scrollTo(0, scrollY);
        },
        onEnd: () => {
          this.preventManualScrolling = false;
          resolve();
        },
      });
    });
  }

  inViewport(element: HTMLElement) {
    const rect = element.getBoundingClientRect();

    const topInsideViewport = rect.top >= 0 && rect.top <= window.innerHeight;
    const bottomInsideViewport =
      rect.bottom >= 0 && rect.bottom <= window.innerHeight;
    const middleInsideViewport =
      rect.top < 0 && rect.bottom > window.innerHeight;

    return topInsideViewport || bottomInsideViewport || middleInsideViewport;
  }

  freezeScrolling() {
    this.frozenY = this.scrollY$.value;

    this.body.style.overflow = 'hidden';
    this.body.style.position = 'fixed';
    this.body.style.top = `-${this.frozenY}px`;
  }

  unfreezeScrolling() {
    this.body.style.overflow = 'auto';
    this.body.style.position = null;
    this.body.style.top = null;

    window.scrollTo({
      top: this.frozenY,
    });
  }
}
