import { TextField } from 'app/components/ui';
import { getComponentForElement } from 'libs/components';
import { filter, map, Subject, switchMap, takeUntil } from 'rxjs';
import {
  LocationApiAccessor,
  LocationApiResult,
} from './location-api-accessor';
import {
  FormControl,
  FormGroup,
  Transformers,
  Validators,
} from 'libs/reactive-forms';
import { Step } from './step';

export interface StepLocationData {
  street: string;
  postCode: string;
  city: string;
}

export class StepLocation extends Step<StepLocationData> {
  private destroy$: Subject<void> | null = null;
  private languageCode = document.documentElement.lang;
  private locationApi = new LocationApiAccessor();

  private streetInput: HTMLInputElement;
  private postCodeInput: HTMLInputElement;
  private cityInput: HTMLInputElement;
  private cityTextField: TextField;

  form = new FormGroup<StepLocationData>({
    street: new FormControl<string>('', [Validators.required]),
    postCode: new FormControl<string>(
      '',
      [Validators.required, Validators.postCode],
      [Transformers.numericOnly]
    ),
    city: new FormControl<string>('', [Validators.required]),
  });

  override onInit(): void {
    this.streetInput = this.host.querySelector<HTMLInputElement>('#street');
    this.postCodeInput =
      this.host.querySelector<HTMLInputElement>('#post-code');
    this.cityInput = this.host.querySelector<HTMLInputElement>('#city');

    this.cityTextField = getComponentForElement(
      this.cityInput.closest('.text-field') as HTMLElement
    );

    this.streetInput.linkControl(this.form.get('street'));
    this.postCodeInput.linkControl(this.form.get('postCode'));
    this.cityInput.linkControl(this.form.get('city'));
  }

  protected override onActivate(): void {
    this.destroy$ = new Subject<void>();

    this.setupCitySearch();

    this.streetInput.focus();
  }

  override deactivate(): void {
    super.deactivate();

    this.destroy$?.next();
    this.destroy$?.complete();
  }

  private setupCitySearch(): void {
    const postCodeControl = this.form.get('postCode');
    const cityControl = this.form.get('city');

    postCodeControl.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        map((postCode) => postCode.toString()),
        filter((postCode) => postCode.length === 4),
        switchMap((postCode) => {
          return this.locationApi.search(this.languageCode, postCode);
        })
      )
      .subscribe((results) => {
        if (results.length > 0 && !cityControl.isTouched) {
          cityControl.setValue(results[0].city);
        }

        this.cityTextField.setDataList(this.mapResultsToDedupedCities(results));
      });
  }

  private mapResultsToDedupedCities(results: LocationApiResult[]): string[] {
    const cities = results.map((result) => result.city);
    return [...new Set(cities)];
  }
}
