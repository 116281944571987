import { emailValidator } from './email-validator';
import { maxLengthValidator } from './max-length-validator';
import { minLengthValidator } from './min-length-validator';
import { phoneValidator } from './phone-validator';
import { postCodeValidator } from './post-code-validator';
import { requiredValidator } from './required-validator';

export * from './validator-function';

export const Validators = {
  required: requiredValidator,
  email: emailValidator,
  postCode: postCodeValidator,
  minLength: minLengthValidator,
  maxLength: maxLengthValidator,
  phone: phoneValidator,
};
