import { ScrollingService } from 'app/services/scrolling.service';

export class SmoothScrollLink {
  private isActivated = true;

  private url: URL;
  private targetElementId = '';

  constructor(
    private root: HTMLAnchorElement,
    private scrollingService: ScrollingService
  ) {}

  setup() {
    this.getURL();

    if (this.isLocalUrl()) {
      this.getScrollTarget();

      this.root.addEventListener('click', (event) => {
        if (this.isActivated) {
          event.preventDefault();
          this.scrollToTarget();
        }
      });
    }
  }

  private getURL() {
    this.url = new URL(this.root.href);
  }

  private isLocalUrl(): boolean {
    const hostIsSame = this.url.host === window.location.host;
    const pathIsSame = this.url.pathname === window.location.pathname;

    return hostIsSame && pathIsSame;
  }

  private getScrollTarget() {
    this.targetElementId = this.url.hash;
  }

  private scrollToTarget() {
    this.scrollingService.scrollToId(this.targetElementId).then(() => {
      window.location.hash = this.targetElementId;
    });
  }

  activate() {
    this.isActivated = true;
  }

  deactivate() {
    this.isActivated = false;
  }
}
