import { FormControl, FormGroup, Validators } from 'libs/reactive-forms';
import { Step } from './step';

export type CustomerType = 'new' | 'existing' | '';

export interface StepExistingCustomerCheckData {
  customerType: CustomerType;
}

export class StepExistingCustomerCheck extends Step<StepExistingCustomerCheckData> {
  private newCustomerInput: HTMLInputElement;
  private existingCustomerInput: HTMLInputElement;

  protected form = new FormGroup<StepExistingCustomerCheckData>({
    customerType: new FormControl<CustomerType>('', [Validators.required]),
  });

  protected override onInit(): void {
    this.newCustomerInput =
      this.host.querySelector<HTMLInputElement>('#new-customer');
    this.existingCustomerInput =
      this.host.querySelector<HTMLInputElement>('#existing-customer');

    this.newCustomerInput.linkControl(this.form.get('customerType'));
    this.existingCustomerInput.linkControl(this.form.get('customerType'));
  }
}
