import { ResponsiveTarget } from 'app/responsive-target';
import { NavigationService } from 'app/services/navigation.service';
import { Component, ComponentRegistry } from 'libs/components';
import { Provider } from 'libs/provider';
import { ResponsiveService } from 'app/services/responsive.service';
import { ScrollingService } from 'app/services/scrolling.service';
import { DesktopNavigationBehavior } from './desktop-navigation-behavior';
import { MobileNavigationBehavior } from './mobile-navigation-behavior';
import { ContactFormStorage } from 'app/components/sections';

export class ResponsiveNavigationComponent extends Component {
  private responsiveService: ResponsiveService<ResponsiveTarget>;
  private scrollingService: ScrollingService;
  private navigationService: NavigationService;
  private contactFormStorage: ContactFormStorage;

  private mobileNavigationBehavior: MobileNavigationBehavior;
  private desktopNavigationBehavior: DesktopNavigationBehavior;

  override getDependencies(): void {
    this.responsiveService = Provider.get(ResponsiveService);
    this.navigationService = Provider.get(NavigationService);
    this.scrollingService = Provider.get(ScrollingService);
    this.contactFormStorage = Provider.get(ContactFormStorage);
  }

  override onInit(): void {
    this.hideContactFormLinkIfAlreadySubmitted();

    this.mobileNavigationBehavior = new MobileNavigationBehavior(
      this.host,
      this.navigationService,
      this.scrollingService
    );
    this.mobileNavigationBehavior.setup();

    this.desktopNavigationBehavior = new DesktopNavigationBehavior(this.host);
    this.desktopNavigationBehavior.setup();

    this.responsiveService.activeTargets.subscribe(() => {
      if (this.responsiveService.isActive(ResponsiveTarget.Large)) {
        this.mobileNavigationBehavior.detach();
        this.desktopNavigationBehavior.attach();
      } else {
        this.desktopNavigationBehavior.detach();
        this.mobileNavigationBehavior.attach();
      }
    });
  }

  private hideContactFormLinkIfAlreadySubmitted() {
    if (this.contactFormStorage.wasContactFormSent()) {
      this.host.classList.add('navigation--no-contact-link');
    }
  }
}

ComponentRegistry.declare('.navigation', ResponsiveNavigationComponent);
