import { ValidatorFunction } from './validator-function';

export const minLengthValidator = (minLength: number) => {
  const validator: ValidatorFunction<string> = (value: string) => {
    const isValid = value.length >= minLength;

    return {
      value,
      isValid,
    };
  };

  return validator;
};
