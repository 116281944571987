import { ValidatorFunction } from './validator-function';

const phoneRegex = /^[+](41|423|49|43|33|39)\d{7,12}$/;

export const phoneValidator: ValidatorFunction<string> = (value: string) => {
  const noWhitespaceValue = value.replace(/\s/g, '');
  const isValid = phoneRegex.test(noWhitespaceValue);

  return {
    value,
    isValid,
  };
};
