import { Component, ComponentRegistry } from 'libs/components';
import { DataList } from './data-list';

export class TextField extends Component {
  private input: HTMLInputElement;

  private hasFocus = false;
  private hasValue = false;
  private isValid = false;
  private wasTouched = false;

  private dataList: DataList | null = null;

  override onInit(): void {
    this.getElements();
    this.addListeners();
  }

  private getElements() {
    this.input =
      this.host.querySelector<HTMLInputElement>('.text-field__input');
  }

  private addListeners() {
    this.input.addEventListener('focus', () => {
      this.hasFocus = true;
      this.dataList?.onInputFocusChange(this.hasFocus);
      this.render();
    });

    this.input.addEventListener('blur', () => {
      this.hasFocus = false;
      this.wasTouched = true;
      this.dataList?.onInputFocusChange(this.hasFocus);
      this.render();
    });

    this.input.addEventListener('input', () => {
      this.hasValue = !!this.input.value;
      this.isValid = this.input.validity.valid;
      this.dataList?.onInputChange();
      this.render();
    });

    // NOTE: custom event, see control-link.ts
    this.input.addEventListener('value-update', () => {
      this.hasValue = !!this.input.value;
      this.isValid = this.input.validity.valid;
      this.render();
    });

    this.input.addEventListener('keydown', (event: KeyboardEvent) => {
      this.dataList?.onKeyDown(event);
    });

    this.input.addEventListener('click', () => {
      this.dataList?.onInputClick();
    });
  }

  private render() {
    this.host.classList.toggle('text-field--focused', this.hasFocus);
    this.host.classList.toggle('text-field--has-value', this.hasValue);
    this.host.classList.toggle(
      'text-field--error',
      this.wasTouched && !this.isValid
    );
  }

  setDataList(values: string[]) {
    if (!this.dataList) {
      this.createDataList();
    }

    this.dataList.setValues(values);
  }

  private createDataList() {
    this.dataList = new DataList(this);

    // NOTE: disable auto complete, 'off' seems to be ignored, so we use 'nope'
    this.input.setAttribute('autocomplete', 'nope');

    this.dataList.addEventListener(
      'select',
      (event: CustomEvent<{ value: string }>) => {
        this.input.value = event.detail.value;
        this.input.dispatchEvent(new Event('input'));
        this.input.focus();
      }
    );

    this.host.appendChild(this.dataList.host);
  }
}

ComponentRegistry.declare('.text-field', TextField);
