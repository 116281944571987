import { ResizeService } from './resize.service';
import { BehaviorSubject } from 'rxjs';
import { isNumber } from 'libs/math';

export interface Target<T> {
  key: T;
  minWidth?: number;
  maxWidth?: number;
  minHeight?: number;
  maxHeight?: number;
}

interface TargetMapValue<T> {
  isActive: boolean;
  target: Target<T>;
}

export class ResponsiveService<T> {
  private targets = new Map<T, TargetMapValue<T>>();

  activeTargets = new BehaviorSubject<Target<T>[]>([]);

  constructor(private resizeService: ResizeService) {}

  setup() {
    this.resizeService.onResize.subscribe(() => {
      this.determineCurrentlyActiveTargets();
    });

    this.determineCurrentlyActiveTargets();
  }

  private determineCurrentlyActiveTargets() {
    let targetsHaveChanged = false;

    this.targets.forEach((t) => {
      const previousIsActive = t.isActive;
      t.isActive = this.checkIfTargetIsActive(t.target);

      targetsHaveChanged =
        previousIsActive !== t.isActive || targetsHaveChanged;
    });

    if (targetsHaveChanged) {
      this.onTargetChange();
    }
  }

  private onTargetChange() {
    this.activeTargets.next(this.activeTargetsToArray());
  }

  private activeTargetsToArray(): Target<T>[] {
    return Array.from(this.targets.values())
      .filter((t) => t.isActive)
      .map((t) => t.target);
  }

  private checkIfTargetIsActive(target: Target<T>): boolean {
    const minWidthSatisfied = !isNumber(target.minWidth)
      ? true
      : target.minWidth <= window.innerWidth;
    const maxWidthSatisfied = !isNumber(target.maxWidth)
      ? true
      : target.maxWidth >= window.innerWidth;
    const minHeightSatisfied = !isNumber(target.minHeight)
      ? true
      : target.minHeight <= window.innerHeight;
    const maxHeightSatisfied = !isNumber(target.maxHeight)
      ? true
      : target.maxHeight >= window.innerHeight;

    return (
      minWidthSatisfied &&
      maxWidthSatisfied &&
      minHeightSatisfied &&
      maxHeightSatisfied
    );
  }

  addTarget(target: Target<T>) {
    if (!this.checkHasValidConfiguration(target)) {
      throw new Error(`target configuration invalid`);
    }

    this.targets.set(target.key, {
      isActive: false,
      target,
    });
  }

  private checkHasValidConfiguration(target: Target<T>): boolean {
    return (
      isNumber(target.minWidth) ||
      isNumber(target.maxWidth) ||
      isNumber(target.minHeight) ||
      isNumber(target.maxHeight)
    );
  }

  anyIsActive(...keys: T[]): boolean {
    for (const key of keys) {
      if (this.isActive(key)) {
        return true;
      }
    }

    return false;
  }

  isActive(key: T): boolean {
    return this.targets.get(key).isActive;
  }
}
