import { Observable } from 'rxjs';
import { FormGroup } from 'libs/reactive-forms';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export abstract class Step<T = any> {
  protected abstract form: FormGroup<T>;
  private backButtons: HTMLButtonElement[] = [];
  private submitButton: HTMLButtonElement;

  constructor(protected host: HTMLFormElement) {}

  init() {
    this.backButtons = Array.from(
      this.host.querySelectorAll<HTMLButtonElement>('.back-button')
    );
    this.submitButton =
      this.host.querySelector<HTMLButtonElement>('.submit-button');

    this.form.validityChanges.subscribe((isValid) => {
      this.submitButton.disabled = !isValid;
    });
    this.submitButton.disabled = !this.form.isValid;

    for (const backButton of this.backButtons) {
      backButton.addEventListener('click', (event) => {
        event.preventDefault();
        event.stopPropagation();

        this.host.dispatchEvent(
          new Event('step:back', {
            cancelable: false,
            bubbles: true,
          })
        );
      });
    }

    this.submitButton.addEventListener('click', (event) => {
      event.preventDefault();
      event.stopPropagation();

      this.host.dispatchEvent(
        new Event('step:submit', {
          cancelable: false,
          bubbles: true,
        })
      );
    });

    this.onInit();
  }

  protected abstract onInit(): void;

  activate() {
    this.host.classList.add('contact-form-step--active');

    this.onActivate();
  }

  protected onActivate() {
    // hook
  }

  deactivate() {
    this.host.classList.remove('contact-form-step--active');
  }

  get value(): T {
    return this.form.value;
  }

  get isValid(): boolean {
    return this.form.isValid;
  }

  get valueChanges(): Observable<T> {
    return this.form.valueChanges;
  }
}
