export class Provider {
  private static provided = new WeakMap<object, unknown>();

  static get<T>(token: object): T {
    return this.provided.get(token) as T;
  }

  static provide<T>(token: object, value: T) {
    this.provided.set(token, value);
  }
}
