import { Observable, Subject } from 'rxjs';
import { ValidatorFunction } from './validators';
import { TransformerFunction } from './transformers';

export abstract class AbstractControl<T = unknown> {
  protected _initialValue: T;
  protected validators: ValidatorFunction<T>[] = [];
  protected transformers: TransformerFunction<T>[] = [];

  protected _value: T;
  protected readonly _value$ = new Subject<T>();

  protected _isValid = false;
  protected readonly _isValid$ = new Subject<boolean>();

  protected _isDirty = false;
  protected _isTouched = false;

  setValue(value: T, { emitEvent } = { emitEvent: true }) {
    this._value = this.transformValue(value);

    this.updateIsDirty();
    this.updateValidity();

    if (emitEvent) {
      this._value$.next(this._value);
    }
  }

  private transformValue(value: T): T {
    let newValue = value;

    for (const transform of this.transformers) {
      newValue = transform(newValue);
    }

    return newValue;
  }

  markAsTouched() {
    this._isTouched = true;
  }

  protected abstract updateIsDirty(): void;
  protected abstract updateValidity(): void;

  get validityChanges(): Observable<boolean> {
    return this._isValid$.asObservable();
  }

  get valueChanges(): Observable<T> {
    return this._value$.asObservable();
  }

  get value(): T {
    return this._value;
  }

  get isDirty(): boolean {
    return this._isDirty;
  }

  get isValid(): boolean {
    return this._isValid;
  }

  get isTouched(): boolean {
    return this._isTouched;
  }

  get initialValue(): T {
    return this._initialValue;
  }
}
