import { ValidatorFunction } from './validator-function';

export const postCodeValidator: ValidatorFunction<string> = (value: string) => {
  const numberValue = parseInt(value);
  const isValid = numberValue >= 1000 && numberValue <= 9999;

  return {
    value,
    isValid,
  };
};
