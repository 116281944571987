import { NavigationService } from 'app/services/navigation.service';
import { Component, ComponentRegistry } from 'libs/components';
import { Provider } from 'libs/provider';

export class HamburgerComponent extends Component {
  private navigationService: NavigationService;

  override getDependencies(): void {
    this.navigationService = Provider.get(NavigationService);
  }

  override onInit(): void {
    this.navigationService.isOpen.subscribe((isOpen) => {
      if (isOpen) {
        this.host.classList.add('hamburger--open');
      } else {
        this.host.classList.remove('hamburger--open');
      }
    });

    this.host.addEventListener('click', this.toggle);
  }

  toggle = () => {
    this.navigationService.toggle();
  };
}

ComponentRegistry.declare('.hamburger', HamburgerComponent);
