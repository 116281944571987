import { Component, ComponentRegistry } from 'libs/components';

export class Select extends Component {
  private select: HTMLSelectElement;
  private hasValue = false;

  override onInit(): void {
    this.getElements();
    this.loop();
  }

  private getElements() {
    this.select = this.host.querySelector('.select__input');
  }

  private loop = () => {
    this.update();
    this.render();

    window.requestAnimationFrame(this.loop);
  };

  private update() {
    this.hasValue = !!this.select.value;
  }

  private render() {
    this.host.classList.toggle('select--has-value', this.hasValue);
  }
}

ComponentRegistry.declare('.select', Select);
