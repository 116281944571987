import { invLerp } from './inv-lerp';
import { lerp } from './lerp';

export function remap(
  value: number,
  fromMin: number,
  fromMax: number,
  toMin: number,
  toMax: number
): number {
  const t = invLerp(value, fromMin, fromMax);
  return lerp(t, toMin, toMax);
}
