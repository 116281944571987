import { AbstractControl } from './abstract-control';

declare global {
  interface HTMLInputElement {
    control: AbstractControl<unknown>;
    linkControl(control: AbstractControl<unknown>): void;
  }

  interface HTMLSelectElement {
    control: AbstractControl<unknown>;
    linkControl(control: AbstractControl<unknown>): void;
  }
}

function linkControl(control: AbstractControl<unknown>) {
  const isCheckbox = this.type === 'checkbox';
  const isRadio = this.type === 'radio';

  this.control = control;

  this.control.valueChanges.subscribe((value: string) => {
    if (isRadio) {
      this.checked = value === this.value;
    } else {
      this.value = value;
    }
    this.dispatchEvent(new Event('value-update'));
  });

  this.addEventListener('input', () => {
    if (isCheckbox) {
      control.setValue(this.checked);
    } else {
      control.setValue(this.value);
    }
  });

  this.addEventListener('blur', () => {
    this.control.markAsTouched();
  });

  const updateValidity = (isValid: boolean) => {
    if (!isValid) {
      this.setCustomValidity('invalid');
    } else {
      this.setCustomValidity('');
    }
    this.checkValidity();
  };

  control.validityChanges.subscribe((isValid) => {
    updateValidity(isValid);
  });
  updateValidity(control.isValid);

  if (isCheckbox) {
    this.checked = control.initialValue as boolean;
  } else if (isRadio) {
    this.checked = control.initialValue === this.value;
  } else {
    this.value = control.initialValue as string;
  }
}

HTMLInputElement.prototype.linkControl = linkControl;
HTMLSelectElement.prototype.linkControl = linkControl;
