import { ResponsiveTarget } from 'app/responsive-target';
import { NavigationService } from 'app/services/navigation.service';
import { Component, ComponentRegistry } from 'libs/components';
import { Provider } from 'libs/provider';
import { ResponsiveService } from 'app/services/responsive.service';
import { ScrollingService } from 'app/services/scrolling.service';
import { DesktopNavigationLinkBehavior } from './desktop-navigation-link-behavior';
import { MobileNavigationLinkBehavior } from './mobile-navigation-link-behavior';

export class NavigationLinkComponent extends Component<HTMLAnchorElement> {
  private navigationService: NavigationService;
  private scrollingService: ScrollingService;
  private responsiveService: ResponsiveService<ResponsiveTarget>;

  private mobileBehavior: MobileNavigationLinkBehavior;

  private desktopBehavior: DesktopNavigationLinkBehavior;

  override getDependencies(): void {
    this.responsiveService = Provider.get(ResponsiveService);
    this.navigationService = Provider.get(NavigationService);
    this.scrollingService = Provider.get(ScrollingService);
  }

  override onInit(): void {
    this.mobileBehavior = new MobileNavigationLinkBehavior(
      this.host,
      this.navigationService
    );
    this.mobileBehavior.setup();

    this.desktopBehavior = new DesktopNavigationLinkBehavior(
      this.host,
      this.scrollingService
    );
    this.desktopBehavior.setup();

    this.responsiveService.activeTargets.subscribe(() => {
      if (this.responsiveService.isActive(ResponsiveTarget.Large)) {
        this.mobileBehavior.detach();
        this.desktopBehavior.attach();
      } else {
        this.desktopBehavior.detach();
        this.mobileBehavior.attach();
      }
    });
  }
}

ComponentRegistry.declare('.navigation-link', NavigationLinkComponent);
