import { ScrollingService } from 'app/services/scrolling.service';
import { NavigationService } from 'app/services/navigation.service';
import { ResponsiveComponentBehavior } from 'libs/components/responsive-component-behavior';

export class MobileLogoBehavior implements ResponsiveComponentBehavior {
  private previousScrollY = 0;

  private isAttached = false;

  constructor(
    private root: HTMLElement,
    private scrollingService: ScrollingService,
    private navigationService: NavigationService
  ) {}

  setup() {
    this.setupScrollListener();
    this.setupNavigationListener();
  }

  attach() {
    this.isAttached = true;
  }

  detach() {
    this.root.classList.remove('logo--reduced');
    this.root.classList.remove('logo--light');
    this.isAttached = false;
  }

  private setupScrollListener() {
    this.scrollingService.scrollY.subscribe((scrollY) => {
      if (this.isAttached) {
        this.updateReducedState(scrollY);
      }
    });
    window.requestAnimationFrame(() => this.updateReducedState(window.scrollY));
  }

  private setupNavigationListener() {
    this.navigationService.isOpen.subscribe((isOpen) => {
      if (this.isAttached) {
        if (isOpen) {
          this.root.classList.add('logo--light');
        } else {
          this.root.classList.remove('logo--light');
        }
      }
    });
  }

  updateReducedState(scrollY: number) {
    const breakpoint = window.innerHeight * 0.5;

    if (this.previousScrollY <= breakpoint && scrollY > breakpoint) {
      this.root.classList.add('logo--reduced');
    } else if (this.previousScrollY > breakpoint && scrollY <= breakpoint) {
      this.root.classList.remove('logo--reduced');
    }

    this.previousScrollY = scrollY;
  }
}
