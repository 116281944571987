import { ResponsiveComponentBehavior } from 'libs/components/responsive-component-behavior';
import { NavigationService } from '../../services/navigation.service';
import { ScrollingService } from '../../services/scrolling.service';

export class MobileNavigationBehavior implements ResponsiveComponentBehavior {
  private scrollContainer: HTMLElement;
  private languageButtons: NodeListOf<HTMLButtonElement>;

  private isAttached = false;

  constructor(
    private root: HTMLElement,
    private navigationService: NavigationService,
    private scrollingService: ScrollingService
  ) {}

  setup() {
    this.scrollContainer = this.root.querySelector(
      '.navigation__scroll-container'
    ) as HTMLElement;
    this.languageButtons =
      this.root.querySelectorAll<HTMLButtonElement>('.language-button');
    this.hideLanguageButtonsFromKeyboardSelection();

    this.navigationService.isOpen.subscribe((isOpen) => {
      if (this.isAttached) {
        if (isOpen) {
          this.resetContentScroll();
          this.freezeScrolling();
          this.makeLanguageButtonsKeyboardSelectable();
          this.root.classList.add('navigation--open');
        } else {
          this.unfreezeScrolling();
          this.hideLanguageButtonsFromKeyboardSelection();
          this.root.classList.remove('navigation--open');
        }
      }
    });
  }

  attach() {
    this.hideLanguageButtonsFromKeyboardSelection();
    this.isAttached = true;
  }

  detach() {
    this.navigationService.close();
    this.makeLanguageButtonsKeyboardSelectable();
    this.isAttached = false;
  }

  freezeScrolling() {
    this.scrollingService.freezeScrolling();
  }

  unfreezeScrolling() {
    this.scrollingService.unfreezeScrolling();
  }

  resetContentScroll() {
    this.scrollContainer.scrollTo({
      top: 0,
    });
  }

  hideLanguageButtonsFromKeyboardSelection() {
    this.languageButtons.forEach((button) => {
      button.tabIndex = -1;
    });
  }

  makeLanguageButtonsKeyboardSelectable() {
    this.languageButtons.forEach((button) => {
      button.tabIndex = 0;
    });
  }
}
