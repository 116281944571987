import { YoutubeService } from 'app/services/youtube.service';
import { Component, ComponentRegistry } from 'libs/components';
import { Provider } from 'libs/provider';

export class VideoBoxComponent extends Component {
  private youtubeService: YoutubeService;

  private playButton: HTMLElement;
  private videoOutlet: HTMLElement;
  private videoOutletId: string;

  private player: YT.Player;
  private videoId: string;

  override getDependencies() {
    this.youtubeService = Provider.get(YoutubeService);
  }

  override onInit(): void {
    this.videoOutlet = this.host.querySelector('.video-box__outlet');
    this.videoOutletId = `video_${this.uid}`;
    this.videoOutlet.setAttribute('id', this.videoOutletId);

    this.videoId = this.host.dataset['videoId'];

    this.playButton = this.host.querySelector('.video-box__play-button');

    this.youtubeService.isReady.subscribe(() => {
      this.player = new YT.Player(this.videoOutletId, {
        width: '100%',
        height: '100%',
        videoId: this.videoId,
      });

      this.playButton.addEventListener('click', () => {
        this.hidePlayButtonAndThumbnail();
        this.player.playVideo();
      });
    });
  }

  private hidePlayButtonAndThumbnail() {
    this.host.classList.add('video-box--played');
  }
}

ComponentRegistry.declare('.video-box', VideoBoxComponent);
