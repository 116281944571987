import { ScrollingService } from 'app/services/scrolling.service';
import { SmoothScrollLink } from 'libs/animation/smooth-scroll-link';
import { ResponsiveComponentBehavior } from 'libs/components/responsive-component-behavior';

export class DesktopNavigationLinkBehavior
  implements ResponsiveComponentBehavior
{
  private smoothScrollLink: SmoothScrollLink;

  constructor(
    private root: HTMLAnchorElement,
    private scrollingService: ScrollingService
  ) {}

  setup() {
    this.smoothScrollLink = new SmoothScrollLink(
      this.root,
      this.scrollingService
    );
    this.smoothScrollLink.setup();
  }

  attach() {
    this.smoothScrollLink.activate();
  }

  detach() {
    this.smoothScrollLink.deactivate();
  }
}
