import { ResponsiveTarget } from 'app/responsive-target';
import { NavigationService } from 'app/services/navigation.service';
import { ResponsiveService } from 'app/services/responsive.service';
import { ScrollingService } from 'app/services/scrolling.service';
import { Component, ComponentRegistry } from 'libs/components';
import { Provider } from 'libs/provider';
import { MobileLogoBehavior } from './mobile-logo-behavior';

export class ResponsiveLogoComponent extends Component {
  private mobileLogoBehavior: MobileLogoBehavior;
  private responsiveService: ResponsiveService<ResponsiveTarget>;
  private scrollingService: ScrollingService;
  private navigationService: NavigationService;

  override getDependencies(): void {
    this.responsiveService = Provider.get(ResponsiveService);
    this.scrollingService = Provider.get(ScrollingService);
    this.navigationService = Provider.get(NavigationService);
  }

  setup() {
    this.mobileLogoBehavior = new MobileLogoBehavior(
      this.host,
      this.scrollingService,
      this.navigationService
    );
    this.mobileLogoBehavior.setup();

    this.responsiveService.activeTargets.subscribe(() => {
      if (this.responsiveService.isActive(ResponsiveTarget.Large)) {
        this.mobileLogoBehavior.detach();
      } else {
        this.mobileLogoBehavior.attach();
      }
    });
  }
}

ComponentRegistry.declare('.logo', ResponsiveLogoComponent);
