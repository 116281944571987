export class ContactFormStorage {
  private readonly STORAGE_KEY = 'contact-form-storage';

  private state = {
    contactFormSent: false,
  };

  constructor() {
    this.readStorage();
  }

  private readStorage(): void {
    const stringifiedState = sessionStorage.getItem(this.STORAGE_KEY);
    if (stringifiedState) {
      try {
        const parsedState = JSON.parse(stringifiedState);
        this.state = parsedState;
      } catch (error) {
        console.error(`Something went wrong while reading session storage`);
      }
    }
  }

  wasContactFormSent(): boolean {
    return this.state.contactFormSent;
  }

  markContactFormAsSent(): void {
    this.state.contactFormSent = true;
    this.saveState();
  }

  private saveState(): void {
    const stringifiedState = JSON.stringify(this.state);
    sessionStorage.setItem(this.STORAGE_KEY, stringifiedState);
  }
}
