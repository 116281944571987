import { DataListItem } from './data-list-item';
import { TextField } from './text-field';

export class DataList extends EventTarget {
  readonly host: HTMLElement;

  private items: DataListItem[] = [];

  private inputHasFocus = false;
  private isShown = false;

  private activeItemIndex = -1;
  private activeItem: DataListItem;

  constructor(readonly textField: TextField) {
    super();
    this.host = document.createElement('div');
    this.host.classList.add('data-list');

    // NOTE: on outside click
    window.addEventListener('click', (event) => {
      if (
        !this.host.contains(event.target as Node) &&
        !this.textField.host.contains(event.target as Node)
      ) {
        this.hide();
      }
    });
  }

  onInputFocusChange(inputHasFocus: boolean) {
    this.inputHasFocus = inputHasFocus;

    if (this.inputHasFocus) {
      this.show();
    }
  }

  onInputChange() {
    if (!this.isShown && this.inputHasFocus) {
      this.show();
      this.setActiveItemIndex(-1, false);
    }
  }

  private show() {
    this.isShown = true;
    this.updateHostClass();
  }

  private hide() {
    this.isShown = false;
    this.setActiveItemIndex(-1, false);
    this.updateHostClass();
  }

  private updateHostClass() {
    this.host.classList.toggle('data-list--show', this.isShown);
  }

  setValues(values: string[]) {
    this.host.innerHTML = '';
    this.items = [];

    for (const value of values) {
      const item = new DataListItem(value);

      item.addEventListener('select-item', () => {
        this.selectActiveItem();
      });

      item.addEventListener('hover-item', () => {
        this.setActiveItemIndex(this.items.indexOf(item));
      });

      this.host.appendChild(item.host);
      this.items.push(item);
    }
  }

  onInputClick() {
    if (!this.isShown) {
      this.show();
      this.setActiveItemIndex(-1, false);
    }
  }

  onKeyDown(event: KeyboardEvent) {
    if (
      ['ArrowUp', 'ArrowDown', 'Home', 'End', 'Enter', 'Escape'].includes(
        event.code
      )
    ) {
      event.preventDefault();
      event.stopPropagation();
    }

    if (!this.isShown) {
      switch (event.code) {
        case 'ArrowUp':
          this.show();
          this.setActiveItemIndex(-1);
          break;
        case 'ArrowDown':
          this.show();
          this.setActiveItemIndex(0);
          break;
      }

      return;
    }

    switch (event.code) {
      case 'ArrowUp':
        this.setActiveItemIndex(this.activeItemIndex - 1);
        break;
      case 'ArrowDown':
        this.setActiveItemIndex(this.activeItemIndex + 1);
        break;
      case 'Home':
        this.setActiveItemIndex(0);
        break;
      case 'End':
        this.setActiveItemIndex(-1);
        break;
      case 'Enter':
        this.selectActiveItem();
        break;
      case 'Escape':
        this.hide();
        break;
      case 'Tab':
        this.hide();
        break;
    }
  }

  private setActiveItemIndex(index: number, wrap = true) {
    if (wrap) {
      if (index < 0) {
        index = this.items.length - 1;
      } else if (index >= this.items.length) {
        index = 0;
      }
    }

    this.activeItemIndex = index;

    this.updateActiveItem();
  }

  private updateActiveItem() {
    for (let i = 0; i < this.items.length; i++) {
      const item = this.items[i];
      item.setActive(i === this.activeItemIndex);
    }

    this.activeItem = this.items[this.activeItemIndex];
  }

  private selectActiveItem(): void {
    if (!this.activeItem) {
      return;
    }

    this.dispatchEvent(
      new CustomEvent('select', { detail: { value: this.activeItem.value } })
    );

    window.requestAnimationFrame(() => {
      this.hide();
    });
  }
}
