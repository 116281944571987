import { ValidatorFunction } from './validator-function';

export const maxLengthValidator = (maxLength: number) => {
  const validator: ValidatorFunction<string> = (value: string) => {
    const isValid = value.length <= maxLength;

    return {
      value,
      isValid,
    };
  };

  return validator;
};
