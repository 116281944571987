import { ResponsiveComponentBehavior } from 'libs/components/responsive-component-behavior';

export class DesktopNavigationBehavior implements ResponsiveComponentBehavior {
  private isAttached = false;

  private languageDropdownRoot: HTMLElement;
  private items: HTMLElement[];
  private languageDropdownOpen = false;

  constructor(private root: HTMLElement) {}

  setup() {
    this.languageDropdownRoot = this.root.querySelector(
      '.navigation-languages'
    );

    this.items = Array.from(
      this.languageDropdownRoot.querySelectorAll<HTMLElement>(
        '.navigation-languages__item'
      )
    );

    this.languageDropdownRoot.addEventListener('click', (event: MouseEvent) => {
      if (this.isAttached) {
        if (this.languageDropdownOpen) {
          this.closeDropdown();
        } else {
          event.preventDefault(); // So links don't get clicked
          this.openDropdown();
        }
      }
    });

    window.addEventListener('click', (event: MouseEvent) => {
      const target = event.target as HTMLElement;

      if (this.isAttached) {
        if (
          !target.closest('.navigation-languages') &&
          this.languageDropdownOpen
        ) {
          this.closeDropdown();
        }
      }
    });
  }

  attach() {
    this.isAttached = true;
  }

  detach() {
    this.closeDropdown();
    this.isAttached = false;
  }

  private openDropdown() {
    this.languageDropdownRoot.classList.add('navigation-languages--open');
    this.languageDropdownOpen = true;

    this.animateIn();
  }

  private async animateIn() {
    const animationPromises = [];
    for (const item of this.items) {
      if (item.classList.contains('navigation-languages__item--active')) {
        continue;
      }

      const animation = item.animate(
        [
          {
            opacity: '0',
          },
          {
            opacity: '1',
          },
        ],
        {
          duration: 200,
          easing: 'ease-out',
        }
      );

      animationPromises.push(animation.finished);
    }

    await Promise.all(animationPromises);
  }

  private closeDropdown() {
    this.languageDropdownRoot.classList.remove('navigation-languages--open');
    this.languageDropdownOpen = false;

    this.animateOut();
  }

  private async animateOut() {
    const animationPromises = [];
    for (const item of this.items) {
      if (item.classList.contains('navigation-languages__item--active')) {
        continue;
      }

      item.style.display = 'block';
      const animation = item.animate(
        [
          {
            opacity: '1',
          },
          {
            opacity: '0',
          },
        ],
        {
          duration: 200,
          easing: 'ease-out',
        }
      );

      animation.onfinish = () => {
        item.style.display = '';
      };

      animationPromises.push(animation.finished);
    }

    await Promise.all(animationPromises);
  }
}
