export class Component<T extends HTMLElement = HTMLElement> {
  constructor(readonly host: T, protected uid: number) {}

  getDependencies() {
    // hook
  }

  onInit() {
    // hook
  }
}
