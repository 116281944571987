export class Slide {
  public readonly backgroundColor: string;

  constructor(protected host: HTMLElement) {
    this.backgroundColor = this.host.dataset['color'];
  }

  async activate() {
    this.host.style.display = 'block';

    // hook
  }

  async deactivate() {
    this.host.style.display = '';

    // hook
  }
}
