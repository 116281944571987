import { Observable, OperatorFunction } from 'rxjs';
import { map } from 'rxjs/operators';
import { fromFetch } from 'rxjs/fetch';

export interface LocationApiResult {
  id: string;
  postCode: number;
  city: string;
}

export interface PostApiResponse {
  records: PostApiRecord[];
}

export interface PostApiRecord {
  recordid: string;
  fields: {
    ortbez27: string;
    postleitzahl: string;
  };
  geometry?: unknown;
}

export class LocationApiAccessor {
  readonly apiUrl =
    'https://swisspost.opendatasoft.com/api/records/1.0/search/';
  readonly staticGetParams =
    '?dataset=plz_verzeichnis_v2&facet=gplz&facet=ortbez18';

  get baseUrl(): string {
    return `${this.apiUrl}${this.staticGetParams}`;
  }

  search(lang: string, searchTerm: string): Observable<LocationApiResult[]> {
    const url = this.createRequestUrl(lang, searchTerm);

    return fromFetch<PostApiResponse>(url, {
      selector: (response) => response.json(),
    }).pipe(
      this.filterRecordsWithoutGeometry(),
      this.mapPostApiRecordToLocationApiResult()
    );
  }

  private filterRecordsWithoutGeometry(): OperatorFunction<
    PostApiResponse,
    PostApiRecord[]
  > {
    return map((result: PostApiResponse) => {
      return result.records.filter((record: PostApiRecord) => {
        return !!record.geometry;
      });
    });
  }

  private mapPostApiRecordToLocationApiResult(): OperatorFunction<
    PostApiRecord[],
    LocationApiResult[]
  > {
    return map((records: PostApiRecord[]) => {
      return records.map((record) => {
        return {
          id: record.recordid,
          postCode: parseInt(record.fields.postleitzahl.toString(), 10),
          city: record.fields.ortbez27,
        } as LocationApiResult;
      }) as LocationApiResult[];
    });
  }

  private createRequestUrl(lang: string, searchTerm: string): string {
    const url = [this.baseUrl];
    url.push(`&lang=${lang}`);
    url.push(`&q=${searchTerm}`);
    return url.join('');
  }
}
