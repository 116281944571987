export type EaseFunction = (progress: number) => number;

export function linear(x: number): number {
    return x;
}

export function easeOutQuint(x: number): number {
    return 1 - Math.pow(1 - x, 5);
}

export function jumpEase(strength: number, min: number): EaseFunction {
    return (x: number) => Math.sin(x * Math.PI) * strength + min;
}
