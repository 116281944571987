import { CssSelector } from './types';
import { Component } from './component';
import { ComponentRegistry } from './component-registry';

export function getChildComponent<T extends Component>(
  parent: HTMLElement,
  selector: CssSelector
): T {
  const hostElement = parent.querySelector<HTMLElement>(selector);
  return getComponentForElement(hostElement);
}

export function getChildComponents<T extends Component>(
  parent: HTMLElement,
  selector: CssSelector
): T[] {
  const components: T[] = [];
  const hostElements = Array.from(
    parent.querySelectorAll<HTMLElement>(selector)
  );
  for (const hostElement of hostElements) {
    const component = getComponentForElement<T>(hostElement);

    if (component) {
      components.push(component);
    }
  }
  return components;
}

export function getComponentForElement<T extends Component>(
  hostElement: HTMLElement
): T {
  const id = parseInt(hostElement.dataset['componentId']);
  return ComponentRegistry.get(id);
}
