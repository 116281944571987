import { Component, ComponentRegistry } from 'libs/components';
import { clamp } from 'libs/math';
import { Provider } from 'libs/provider';
import { ContactFormStorage } from './contact-form-storage';
import { Step } from './step';
import { StepContactDetails } from './step-contact-details';
import { StepExistingCustomerCheck } from './step-existing-customer-check';
import { StepLocation } from './step-location';
import { StepName } from './step-name';

export class ContactSection extends Component {
  private form: HTMLFormElement;
  private finalSubmitButton: HTMLButtonElement;
  private steps: Step[] = [];
  private storage: ContactFormStorage;

  stepIndicators: HTMLButtonElement[] = [];
  activeStepIndex = 0;
  activeStep: Step;

  override getDependencies(): void {
    this.storage = Provider.get(ContactFormStorage);
  }

  override onInit(): void {
    this.hideIfAlreadyFilledOut();
    this.getElements();
    this.addButtonListeners();
    this.createSteps();
    this.createIndicators();
    this.goToStep(this.activeStepIndex);
  }

  private hideIfAlreadyFilledOut() {
    if (this.storage.wasContactFormSent()) {
      this.host.style.display = 'none';
    }
  }

  private getElements() {
    this.form = this.host.querySelector('.contact-form__form');
    this.finalSubmitButton = this.host.querySelector('.submit-button--final');
  }

  private createSteps() {
    const stepExistingCustomerCheckHost =
      this.host.querySelector<HTMLFormElement>(
        '.contact-form-step--existing-customer-check'
      );
    const stepNameHost = this.host.querySelector<HTMLFormElement>(
      '.contact-form-step--name'
    );
    const stepLocationHost = this.host.querySelector<HTMLFormElement>(
      '.contact-form-step--location'
    );
    const stepContactDetailsHost = this.host.querySelector<HTMLFormElement>(
      '.contact-form-step--contact-details'
    );

    const stepExistingCustomerCheck = new StepExistingCustomerCheck(
      stepExistingCustomerCheckHost
    );

    const stepName = new StepName(stepNameHost);
    stepExistingCustomerCheck.valueChanges.subscribe((value) => {
      stepName.setCustomerType(value.customerType);
    });

    const stepLocation = new StepLocation(stepLocationHost);
    const stepContactDetails = new StepContactDetails(stepContactDetailsHost);

    this.steps.push(stepExistingCustomerCheck);
    this.steps.push(stepName);
    this.steps.push(stepLocation);
    this.steps.push(stepContactDetails);

    for (const step of this.steps) {
      step.init();
    }
  }

  private createIndicators() {
    const indicatorOutlet = this.host.querySelector(
      '.contact-form__step-indicators'
    );

    const line = document.createElement('div');
    line.classList.add('step-indicator-line');

    for (let i = 0; i < this.steps.length; i++) {
      const indicator = document.createElement('button');
      indicator.innerText = `${i + 1}`;
      indicator.classList.add('step-indicator-button');

      indicator.addEventListener('click', () => {
        this.goToStep(i);
      });

      this.stepIndicators.push(indicator);
      indicatorOutlet.appendChild(indicator);

      if (i !== this.steps.length - 1) {
        indicatorOutlet.appendChild(line.cloneNode());
      }
    }
  }

  private nextStep() {
    this.goToStep(this.activeStepIndex + 1);
  }

  private previousStep() {
    this.goToStep(this.activeStepIndex - 1);
  }

  private goToStep(index: number) {
    const previousIndex = this.activeStepIndex;
    this.activeStepIndex = clamp(index, 0, this.steps.length - 1);

    const previousStep = this.steps[previousIndex];
    this.activeStep = this.steps[this.activeStepIndex];

    previousStep.deactivate();
    this.activeStep.activate();

    this.updateIndicators();
  }

  private updateIndicators() {
    for (let i = 0; i < this.stepIndicators.length; i++) {
      const indicator = this.stepIndicators[i];

      const isDisabled = i > this.activeStepIndex;
      indicator.disabled = isDisabled;
    }
  }

  private addButtonListeners() {
    this.form.addEventListener('step:back', () => {
      this.previousStep();
    });

    this.form.addEventListener('step:submit', () => {
      const isLastStep = this.activeStepIndex === this.steps.length - 1;

      if (isLastStep) {
        this.submit();
      } else if (this.activeStep.isValid) {
        this.nextStep();
      }
    });
  }

  private submit() {
    this.finalSubmitButton.disabled = true;

    const method = this.form.getAttribute('method') || 'POST';
    const action = this.form.getAttribute('action');
    const formData = new FormData(this.form);

    const request = new XMLHttpRequest();
    request.open(method.toUpperCase(), action);
    request.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    request.onload = () => {
      if (request.status === 200) {
        this.storage.markContactFormAsSent();

        const redirectURL = request.responseURL;
        window.location.href = redirectURL;
      } else {
        console.error(request);
      }

      this.finalSubmitButton.disabled = false;
    };

    request.send(formData);
  }
}

ComponentRegistry.declare('.contact-section', ContactSection);
