import { lerp } from '../math/lerp';
import { EaseFunction, linear } from './easing';
import { clamp01 } from '../math/clamp';

export type TransitionUpdateCallback = (value: number) => void;
export type TransitionEndCallback = () => void;
export type TransitionEaseFactoryFunction = EaseFunction;

export interface TransitionConfig {
  from: number;
  to: number;
  duration: number;
  onUpdate?: TransitionUpdateCallback;
  onEnd?: TransitionEndCallback;
  ease?: TransitionEaseFactoryFunction;
}

export function transition(config: TransitionConfig) {
  let startTimestamp = 0;
  let value = config.from;

  let easeFunction = linear;

  if (config.ease) {
    easeFunction = config.ease;
  }

  const step = (timestamp: number) => {
    if (!startTimestamp) {
      startTimestamp = timestamp;
    }

    const t = clamp01((timestamp - startTimestamp) / config.duration);
    value = lerp(easeFunction(t), config.from, config.to);

    if (config.onUpdate) {
      config.onUpdate(value);
    }

    if (t < 1) {
      window.requestAnimationFrame(step);
    } else {
      if (config.onEnd) {
        config.onEnd();
      }
    }
  };

  window.requestAnimationFrame(step);
}
