import { Component, ComponentRegistry } from 'libs/components';

export class Footer extends Component {
  private backToTopButton!: HTMLButtonElement;

  override onInit(): void {
    this.getElements();
    this.addListeners();
  }

  private getElements() {
    this.backToTopButton = this.host.querySelector('.back-to-top');
  }

  private addListeners() {
    this.backToTopButton.addEventListener('click', () => {
      this.scrollToTop();
    });
  }

  private scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }
}

ComponentRegistry.declare('.footer', Footer);
