import { FormControl, FormGroup, Validators } from 'libs/reactive-forms';
import { Step } from './step';
import { CustomerType } from './step-existing-customer-check';

export interface StepNameData {
  salutation: string;
  firstName: string;
  lastName: string;
}

export class StepName extends Step<StepNameData> {
  private salutationInput: HTMLSelectElement;
  private firstNameInput: HTMLInputElement;
  private lastNameInput: HTMLInputElement;

  private customerType: CustomerType;

  protected form = new FormGroup<StepNameData>({
    salutation: new FormControl<string>('', [Validators.required]),
    firstName: new FormControl<string>('', [Validators.required]),
    lastName: new FormControl<string>('', [Validators.required]),
  });

  override onInit(): void {
    this.salutationInput =
      this.host.querySelector<HTMLSelectElement>('#salutation');
    this.firstNameInput =
      this.host.querySelector<HTMLInputElement>('#first-name');
    this.lastNameInput =
      this.host.querySelector<HTMLInputElement>('#last-name');

    this.salutationInput.linkControl(this.form.get('salutation'));
    this.firstNameInput.linkControl(this.form.get('firstName'));
    this.lastNameInput.linkControl(this.form.get('lastName'));
  }

  setCustomerType(customerType: CustomerType) {
    this.customerType = customerType;
  }

  protected override onActivate(): void {
    this.host.classList.toggle(
      `contact-form-step--new-customer`,
      this.customerType === 'new'
    );

    this.host.classList.toggle(
      `contact-form-step--existing-customer`,
      this.customerType === 'existing'
    );

    if (this.customerType === 'new') {
      this.salutationInput.focus();
    }
  }
}
