import { numericOnly } from './numeric-only-transformer';
import { cleanWhiteSpace } from './clean-white-space-transformer';
import { maxLength } from './max-length';

export * from './transformer-function';

export const Transformers = {
  numericOnly: numericOnly,
  cleanWhiteSpace: cleanWhiteSpace,
  maxLength: maxLength,
};
