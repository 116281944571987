import { ResponsiveComponentBehavior } from 'libs/components/responsive-component-behavior';
import { NavigationService } from '../../services/navigation.service';

export class MobileNavigationLinkBehavior
  implements ResponsiveComponentBehavior
{
  private isAttached = false;

  constructor(
    private root: HTMLAnchorElement,
    private navigationService: NavigationService
  ) {}

  setup() {
    this.root.tabIndex = -1;

    this.navigationService.isOpen.subscribe((isOpen) => {
      if (this.isAttached) {
        if (isOpen) {
          this.root.tabIndex = 0;
        } else {
          this.root.tabIndex = -1;
        }
      }
    });

    this.root.addEventListener('click', this.onClick);
  }

  onClick = () => {
    if (this.isAttached) {
      this.navigationService.close();
    }
  };

  attach() {
    this.root.tabIndex = -1;
    this.isAttached = true;
  }

  detach() {
    this.root.tabIndex = 0;
    this.isAttached = false;
  }
}
