import {
  FormControl,
  FormGroup,
  Transformers,
  Validators,
} from 'libs/reactive-forms';
import { Step } from './step';

export interface StepContactDetailsData {
  company: string;
  email: string;
  phone: string;
  optIn: boolean;
}

export class StepContactDetails extends Step<StepContactDetailsData> {
  private companyInput: HTMLInputElement;
  private emailInput: HTMLInputElement;
  private phoneInput: HTMLInputElement;
  private optInInput: HTMLInputElement;

  form = new FormGroup<StepContactDetailsData>({
    company: new FormControl<string>('', [Validators.required]),
    email: new FormControl<string>('', [Validators.required, Validators.email]),
    phone: new FormControl<string>(
      '',
      [Validators.required, Validators.phone],
      [Transformers.cleanWhiteSpace, Transformers.maxLength(12, true)]
    ),
    optIn: new FormControl<boolean>(false, []),
  });

  override onInit(): void {
    this.companyInput = this.host.querySelector<HTMLInputElement>('#company');
    this.emailInput = this.host.querySelector<HTMLInputElement>('#email');
    this.phoneInput = this.host.querySelector<HTMLInputElement>('#phone');
    this.optInInput =
      this.host.querySelector<HTMLInputElement>('#wants-newsletter');

    this.companyInput.linkControl(this.form.get('company'));
    this.emailInput.linkControl(this.form.get('email'));
    this.phoneInput.linkControl(this.form.get('phone'));
    this.optInInput.linkControl(this.form.get('optIn'));
  }

  protected override onActivate(): void {
    this.companyInput.focus();
  }
}
