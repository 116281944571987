import { BehaviorSubject, Observable } from 'rxjs';

export interface WindowResizeEvent {
  previousWidth: number;
  previousHeight: number;
  width: number;
  height: number;
}

export class ResizeService {
  private onResize$ = new BehaviorSubject<WindowResizeEvent>({
    previousWidth: window.innerWidth,
    previousHeight: window.innerHeight,
    width: window.innerWidth,
    height: window.innerHeight,
  });

  get onResize(): Observable<WindowResizeEvent> {
    return this.onResize$.asObservable();
  }

  constructor() {
    window.addEventListener('resize', () => {
      this.onResize$.next({
        previousWidth: this.onResize$.value.width,
        previousHeight: this.onResize$.value.height,
        width: window.innerWidth,
        height: window.innerHeight,
      });
    });
  }
}
