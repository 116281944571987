export class DataListItem extends EventTarget {
  readonly host: HTMLElement;

  private _isActive = false;
  get isActive(): boolean {
    return this._isActive;
  }

  constructor(readonly value: string) {
    super();
    this.host = document.createElement('div');
    this.host.classList.add('data-list__item');
    this.host.textContent = value;

    this.host.addEventListener('click', () => {
      this.dispatchEvent(new CustomEvent('select-item'));
    });

    this.host.addEventListener('mousemove', () => {
      this.dispatchEvent(new CustomEvent('hover-item'));
    });
  }

  setActive(active: boolean) {
    this.host.classList.toggle('data-list__item--active', active);
  }
}
