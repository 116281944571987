import { ValidatorFunction } from './validator-function';

const emailRegex = /^\S+@\S+\.\S+$/;

export const emailValidator: ValidatorFunction<string> = (value: string) => {
  const isValid = emailRegex.test(value);

  return {
    value,
    isValid,
  };
};
