import { Component, ComponentRegistry } from 'libs/components';
import { remap } from 'libs/math';

interface Indicator {
  index: number;
  element: HTMLElement;
  item: HTMLElement;
}

export class IconSlider extends Component {
  private scrollContainer: HTMLElement;
  private items: HTMLElement[];
  private indicatorsContainer: HTMLElement;
  private indicators: Indicator[] = [];

  override onInit(): void {
    this.getElements();
    this.createIndicators();
    this.addScrollListener();
    this.updateActiveIndicator();
  }

  private getElements() {
    this.scrollContainer = this.host.querySelector(
      '[data-ic-scroll-container]'
    );
    this.items = Array.from(this.host.querySelectorAll('[data-ic-item]'));
    this.indicatorsContainer = this.host.querySelector('[data-ic-indicators]');
  }

  private createIndicators() {
    for (let i = 0; i < this.items.length; i++) {
      const element = document.createElement('div');
      element.className = 'ic-indicator';

      const indicator = {
        index: i,
        element: element,
        item: this.items[i],
      };

      element.addEventListener('click', () => {
        this.scrollTo(indicator);
      });

      this.indicatorsContainer.appendChild(element);
      this.indicators.push(indicator);
    }
  }

  private addScrollListener() {
    this.scrollContainer.addEventListener('scroll', () => {
      this.updateActiveIndicator();
    });
  }

  private updateActiveIndicator() {
    const scrollPosition = this.scrollContainer.scrollLeft;
    const maxScroll =
      this.scrollContainer.scrollWidth - this.scrollContainer.clientWidth;

    const closestIndex = remap(
      scrollPosition,
      0,
      maxScroll,
      0,
      this.items.length - 1
    );

    const activeIndex = Math.round(closestIndex);
    this.setIndicatorActive(activeIndex);
  }

  private setIndicatorActive(index: number) {
    for (let i = 0; i < this.indicators.length; i++) {
      this.indicators[i].element.classList.toggle(
        'ic-indicator--active',
        i === index
      );
    }
  }

  private scrollTo(indicator: Indicator) {
    const itemPosition = indicator.item.offsetLeft;
    const itemCenter = indicator.item.clientWidth * 0.5;
    const windowCenter = window.innerWidth * 0.5;

    const scrollPosition = itemPosition - windowCenter + itemCenter;

    this.scrollContainer.scrollTo({
      top: 0,
      left: scrollPosition,
      behavior: 'smooth',
    });
  }
}

ComponentRegistry.declare('.icon-slider', IconSlider);
