import { BehaviorSubject, Observable } from 'rxjs';

export class NavigationService {
  private _isOpen$ = new BehaviorSubject<boolean>(false);

  get isOpen(): Observable<boolean> {
    return this._isOpen$;
  }

  open() {
    this._isOpen$.next(true);
  }

  close() {
    this._isOpen$.next(false);
  }

  toggle() {
    if (this._isOpen$.value) {
      this.close();
    } else {
      this.open();
    }
  }
}
