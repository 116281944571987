import { ScrollingService } from 'app/services/scrolling.service';
import { SmoothScrollLink } from 'libs/animation/smooth-scroll-link';
import { Component, ComponentRegistry } from 'libs/components';
import { Provider } from 'libs/provider';

export class AnimatedLink extends Component<HTMLAnchorElement> {
  private scrollingService: ScrollingService;

  private smoothScrollLink: SmoothScrollLink;

  override getDependencies(): void {
    this.scrollingService = Provider.get(ScrollingService);
  }

  override onInit(): void {
    this.smoothScrollLink = new SmoothScrollLink(
      this.host,
      this.scrollingService
    );
    this.smoothScrollLink.setup();
  }
}

ComponentRegistry.declare('.animated-link', AnimatedLink);
