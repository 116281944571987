import { TransformerFunction } from './transformer-function';

export const maxLength = (
  maxLength: number,
  ignoreWhiteSpace = false
): TransformerFunction<string> => {
  return (value: string) => {
    let output = '';
    let charCount = 0;

    for (const char of value) {
      if (!ignoreWhiteSpace || char !== ' ') {
        charCount++;
      }

      if (charCount > maxLength) {
        break;
      }

      output += char;
    }

    return output;
  };
};
