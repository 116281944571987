import { BehaviorSubject, filter, Observable } from 'rxjs';

export class YoutubeService {
  private isReady$ = new BehaviorSubject<boolean>(false);

  get isReady(): Observable<boolean> {
    return this.isReady$.asObservable().pipe(filter(Boolean));
  }

  loadAPI() {
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    window['onYouTubeIframeAPIReady'] = () => {
      this.isReady$.next(true);
    };
  }
}
